var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v(_vm._s(_vm.title))]),_c('GChart',{staticClass:"skill-chart",attrs:{"type":"BarChart","data":[['Skill', 'Value', {type: 'string', role: 'tooltip'}] ].concat( _vm.dataWithTooltips),"options":{
            backgroundColor: 'transparent',
            legend: {
                position: 'none'
            },
            fontName: 'Quicksand',
            fontSize: 14,
            colors: ['#4285f4'],
            hAxis: {
                textPosition: 'none',
                minValue: 0,
                maxValue: 4,
                gridlines: {
                    color: 'transparent'
                }
            },
            height: this.rowHeight + 10,
            chartArea: {
                left: '20%',
                height: this.rowHeight,
                width: '80%'
            }
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }